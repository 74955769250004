<template>
  <div class="card">

    <div class="page-header page-header-light" >
      <div class="page-header-content header-elements-lg-inline" >
        <div class="page-title d-flex" style="padding: 9px;">
          <h6>
            <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Cash Payment </span> - List
          </h6>
          <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
        </div>

        <div class="header-elements d-none">
          <form action="#">
            <div class="form-group">

              <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                <div class="form-control-feedback">
                  <i class="icon-search4"></i>
                </div>

                <div class="input-group-append position-static">
                  <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
                    <i class="icon-gear"></i>
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div class="card-body">
      <div class="table-responsive">
        <table id="voucher-table"
               class="table table-bordered table-columned"
               data-search="false"
               data-pagination="true"
               data-show-refresh="false"
               data-show-columns="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-show-footer="false"
               data-toggle="context"
               data-target=".context-table">
          <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-formatter="runningFormatter" data-width="75" >S.No</th>
              <th data-width="100" data-field="doc_no" data-align="center">Doc No</th>
              <th data-width="107" data-field="doc_date" data-formatter="dateFormatter" >Date</th>
              <th data-field="details" data-formatter="ledgerFormatter" >Descrption</th>
              <th data-field="remarks" >Narration</th>
              <th data-field="amount" data-width="200" data-align="right" data-formatter="indianFormat">Amount</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
        </div>
      </div>
      <!-- End of Context Menu -->


      <vmodal name="voucher-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="475" @before-open="beforeOpen" @before-close="beforeClose" >
        <CashPaymentForm v-bind:myvoucher="voucher" ></CashPaymentForm>
      </vmodal>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone'
  import CashPaymentForm from './CashPaymentForm'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'CashPaymentView',
    components: {
      CashPaymentForm
    },
    store,
    data () {
      return {
        mytable: {},
        voucher: JSON.parse('{"id":0,"status":"A","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","voucher_type":{"id":1,"type":0,"status":"A","name":""},"ledger":{"id":1,"group_id":0,"status":"A","type":0,"name":"","alias":"","open_bal":0,"group":{"id":0,"name":"","alias":"","parent_id":0,"nature":2,"is_profit":false,"is_subledger":false,"is_net_bal":false,"status":"A","type":0}},"list":[]}')
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

      self.$data.mytable = $('#voucher-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      $('#voucher-table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyVoucher(id);
          }else if ($(e.target).text() === 'Cancel') {
            self.cancelVoucher(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {

        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

        $('#voucher-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide();

      });

      self.loadData();

    },
    methods:{
      showModal () {
        this.$modal.show('voucher-window');
      },
      beforeOpen(){

      },
      beforeClose(){

      },
      closeModal () {
        this.$modal.hide('voucher-window');
      },
      loadData(){
        let self = this;
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/simplevoucher/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if( _.isArray (resp.data)) {
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        });

      },
      modifyVoucher(id){
        let self = this;
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.voucher = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/simplevoucher/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {

            self.$data.voucher = resp.data;
            self.$data.voucher.doc_date = resp.data.doc_date.slice(0,10);



            self.$data.voucher.details.forEach(function (det,i) {
              if(det.idx == 0){
                self.$data.voucher.ledger = det.ledger;
                self.$data.voucher.details.splice(i, 1);
              }
            });

            self.$modal.show('voucher-window');
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
      cancelVoucher (id) {
        let self = this;

        self.$data.voucher.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.voucher)
        };

        swal({
          title: 'What is the reason for cancel this voucher?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function(value) {
            return !value && 'You need to write something!'
          }
        }).then(function(result) {
          if(result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/voucher/${id}/?remarks=${result.value}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('voucher-window');
              if(resp.accepted){
                self.loadData();
                swal({
                  title: 'Canceled!',
                  text: 'Your request has been processed',
                  type: 'success'
                });
              }else {
                swal({ title: "Oops", text: resp.message, type: "info" });
              }

            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      },
    }
  }

</script>

<style scoped>

</style>
